import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import QuestionMarkCircleIcon from "../assets/logo/question-mark-circle.svg";
import { useState } from "react";

export default function Searchbar({ value, handleChange, title, placeholder }) {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div>
        <div
          className="items-start grid grid-cols-2 sm:text-sm xl:text-base justify-between my-5"
          htmlFor="søgeord"
        >
          <div className="col-span-full flex order-1 sm:col-span-1">
            <div>{title}</div>
          </div>
          <div
            className="col-span-full flex sm:justify-end order-3 sm:order-2 sm:col-span-1 relative"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="text-sm md:text-base">Hordan finder jeg URL'en til mit feed?</div>
            <div>
              <button>
                <img
                  className="w-4 h-4 ml-2 mt-1 block"
                  src={QuestionMarkCircleIcon}
                  alt="question"
                />
              </button>
            </div>
            <div className="absolute top-6 right-0 z-20 bg-black rounded-lg w-96 text-left">
              {hover && (
                <div className="p-5">
                  {
                    <div>
                      <div className="text-white text-sm font-semibold">
                        Hvordan finder jeg URL'en til mit feed?
                      </div>
                      <div className="text-white text-opacity-75 text-sm font-normal">
                        Du bruger oftest dit feed til Google Ads annoncering og
                        annoncering på Meta Ads. Tag et kig i de to platforme og
                        led efter dit feed. Hvis du ikke finder det, kan du
                        typisk finde det i dit CMS.
                      </div>
                      <div className="text-white text-sm font-semibold mt-3">
                        Hvordan finder jeg URL'en til mit feed?
                      </div>
                      <div className="text-white text-opacity-75 text-sm font-normal">
                        Ræk ud til os på alo@conversio.dk, så hjælper vi dig på
                        vej.
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>

          <div className="col-span-full relative order-2 sm:order-3 sm:pt-1 lg:pt-0">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="feed"
              name="feed"
              className=" bg-neutral-100 w-full block rounded-md border-0 lg:py-4 pl-10 py-3 pr-3 text-black text-sm font-semibold ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
              placeholder={placeholder}
              type="text"
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
