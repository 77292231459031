import { useState } from "react";
import PrimaryButton from "./PrimaryButton";

export default function Signup({ value, onClose, URL }) {
  const [email, setEmail] = useState("");
  const [isCliked, setIsClicked] = useState(false);

  // handlesubmit is a function that takes in an event as a parameter.
  async function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);
    data.append("Feed", URL);
    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/6003542/3i9y0i8/silent",
        {
          method: "POST",
          body: data,
        }
      );
      if (response.ok) {
        setIsClicked(true);
      } else {
        console.log("error failed to download data");
      }
    } catch (error) {
      console.log("error occured: " + error);
    }
    setTimeout(() => {
      setIsClicked(false);
      onClose();
    }, 10000);
  }
 //When user has clicked the button, show the sign up form and when user has signed up show the success button.
  return (
    <div className="bg-white shadow sm:rounded-lg absolute lg:top-24 lg:right-7 lg:w-[26rem] z-20 my-3">
      <div className="px-4 py-6 sm:px-4 ">
        <h3 className="text-base font-semibold leading-6 text-gray-900 justify-start flex">
          Få tilsendt prisanalysen
        </h3>
        <div className="mt-2 flex my-2 max-w-xl text-sm text-gray-500 text-left">
          <p>Indtast din email og så sender vi analysen indenfor 24 timer</p>
        </div>
        <form onSubmit={handleSubmit} className=" sm:flex sm:items-center">
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Din email"
            />
          </div>
          {value ? (
            <div>
              {isCliked ? (
                <PrimaryButton />
              ) : (
                <div>
                  <button
                  id="form_submit"
                    type="submit"
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-9 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    Send
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </form>
      </div>
    </div>
  );
}
