import check from "../assets/logo/check.svg";

export default function PrimaryButton() {
  return (
    <button
      type="submit"
      className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-green-500 px-7 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 sm:ml-3 sm:mt-0 sm:w-auto"
    >
      <img src={check} alt="check" className="h-4 w-4 mr-1" />
      Sendt
    </button>
  );
}
