import { useState } from "react";
import Header from "../components/Header";
import HorizontalCards from "../components/Cards";
import Searchbar from "../components/SearchBar";
import DownloadButton from "../components/DownloadButton";
export default function PriceComparison() {
  const [URL, setURL] = useState(""); // Maintain state for URL value
  return (
    <div>
      <Header />
      <div className="bg-indigo-50">
      <div className="max-w-8xl mx-auto grid grid-cols-12 gap-3 select-none p-6 md:p-0">
          <div className="col-span-full sm:col-span-8 md:ml-8 lg:col-span-9 xl:col-span-10">
            <Searchbar
              title={"Indtast URL'en til dit feed"}
              placeholder="URL'en til dit feed"
              value={URL}
              handleChange={(e) => setURL(e.target.value)}
            />
          </div>
          <div className="col-span-full sm:col-span-4 lg:col-span-3 xl:col-span-2">
            <DownloadButton URL={URL} setURL={setURL} />
          </div>
        </div>
      </div>
      <div className="max-w-8xl mx-auto px-6 sm:px-8">
        <div className="text-start text-black text-base font-semibold mt-14 mb-4">Få et større indblik i din prissammensætning</div>
        <HorizontalCards />
      </div>
    </div>
  );
}
