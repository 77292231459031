import wordmark from "../assets/images/wordmark_Black_RGB.png";
const navigation = [{ name: "Price comparison Insights" }];

export default function Header() {
  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex items-center justify-between py-2 max-w-md lg:max-w-8xl 2xl:px-7 sm:px-7"
        aria-label="Global"
      >
        <div className="flex items-center gap-x-5 px-6 md:px-0">
          <p className="py-4">
            <img src={wordmark} alt="Conversio Logo" width={120} height={120} />
          </p>
          <div className="border-l border-zinc-200 h-7"></div>
          <div className="lg:flex">
            {navigation.map((item) => (
              <p
                key={item.name}
                className="text-neutral-800 text-sm lg:text-xl font-light leading-6"
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>

        <div className="hidden lg:flex ">
          <a
            href="https://conversio.dk/"
            target="_blank"
            rel="noreferrer"
            className="text-black text-sm font-normal px-1"
          >
           Gå til conversio.dk
          </a>
        </div>
      </nav>
      {/* <div className="border-t border-zinc-200 py-4"></div> */}
    </header>
  );
}
