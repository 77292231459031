import { useEffect, useRef, useState } from "react";
import Signup from "../components/SignUp";

export default function DownloadButton({ URL, setURL }) {
  const [showSignup, setShowSignup] = useState(false);
  const signupRef = useRef(null);

  useEffect(() => {
    // // Handler to check if user clicks outside of element
    const handleClick = (e) => {
      if (signupRef.current && !signupRef.current.contains(e.target)) {
        setShowSignup(false);
      }
    };
    // Bind the event listener
    document.body.addEventListener("click", handleClick);
    // cleanup
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div>
      {showSignup !== false ? (
        <div className="relative">
          <button
          id="form_start"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowSignup(true);
            }}
            className="rounded-md w-full sm:w-auto bg-indigo-600 text-center text-sm font-semibold text-white shadow-sm hover:bg-grey-500 py-4 px-4 lg:my-11"
          >
            Send prisanalysen
          </button>
          <div ref={signupRef}>
            <Signup
              style={{ display: showSignup ? "block" : "none" }}
              onClick={(e) => e.stopPropagation()}
              value={showSignup}
              onClose={() => setShowSignup(false)}
              URL={URL}
              setURL={setURL}
            />
          </div>
        </div>
      ) : (
        <div className="relative">
          <button
          id="form_start"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowSignup(true);
            }}
            className="rounded-md w-full sm:w-auto bg-indigo-600 text-center text-sm font-semibold text-white shadow-sm hover:bg-grey-500 py-4 px-4 lg:my-11"
          >
            Send prisanalysen
          </button>
        </div>
      )}
    </div>
  );
}
