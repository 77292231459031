import "./App.css";
import PriceComparison from "./pages/PriceComparison";
function App() {
  return (
    <div className="App">
      <PriceComparison />
    </div>
  );
}

export default App;
