const cards = [
  {
    title: "Forøg din indtjening",
    description:
      "Du har med stor sandsynlighed den laveste pris på nogle af dine produkter sammenlignet med konkurrenterne. Men hvor lav er den pris i virkeligheden? Det er værd at overveje, om din prisforskel i forhold til din nærmeste konkurrent er så betydelig, at du kan overveje at hæve dine priser, mens du stadig forbliver den mest prisvenlige mulighed. Dette kan ofte være den mest enkle måde at øge din indtjening på uden at skabe negative reaktioner hos dine kunder.",
  },
  {
    title: "Styrk din positionering i markedet",
    description:
      "Prissammenligning og prismatch af dine produkter med konkurrenterne er en direkte måde at placere din virksomhed på markedet over for det segment, der er prisbevidst og søger efter de bedste tilbud. Du demonstrerer din markedsindsigt, hvilket styrker opfattelsen af din virksomhed som det optimale valg for forbrugere, der søger værdi for pengene. Dette kan opbygge tillid og skabe loyalitet blandt dine kunder, hvilket igen forstærker din position på markedet.",
  },
  {
    title: "Få mere ud af dine kliks",
    description:
      "Klikpriser stiger årligt med ca. 20%, hvilket gør det vigtigere end nogensinde at få mest muligt ud af hvert eneste klik. Google Shopping fremstår som den mest oplagte platform for at præsentere dine bedste priser. Dette indebærer også, at du på forhånd har etableret forventninger hos forbrugeren om, hvad de kan forvente, når de besøger dit websted, hvilket betyder, at beslutningsprocessen fra klik til køb bliver betydeligt kortere.",
  },
  {
    title: "Find dine nærmeste konkurrenter",
    description:
      "Du har sikkert en idé om, hvem dine nærmeste konkurrenter er. Men ved du det med sikkerhed? Vores prissammenligningsværktøj viser dig alle de konkurrenter, der sælger det samme produkt som dig. Udnyt denne information til at opdage nye muligheder, få en bedre forståelse af din position i forhold til konkurrenterne og få et mere nuanceret billede af, om dine opfattelser af dine nærmeste konkurrenter faktisk stemmer overens med virkeligheden.",
  },
  // More cards...
];

export default function Cards() {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 ">
      {cards.map((card) => (
        <div
          key={card.title}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-neutral-50 px-6 py-14 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 "
        >
          <div className="flex-shrink-0"></div>
          <div className="min-w-0 flex-1">
            <div className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <div className="text-sm font-semibold text-black text-start">
                {card.title}
              </div>
              <div className=" text-sm text-black font-normal leading-tight text-start">
                {card.description}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
